import * as jwt from "jsonwebtoken";

import { apiConstants } from "../constants";

const getUserInfo = () => {
  const token = localStorage.getItem(apiConstants.AUTH_TOKEN);
  const decoded = jwt.decode(token);
  return decoded.user;
};

/**
 * Test Whether User Is Admin
 * @param {object} user
 */
const isAdmin = user => {
  if (typeof user.roles === "undefined") {
    return false;
  }
  return user.roles.some(role => role.name === "admin");
};

export const userServices = {
  getUserInfo,
  isAdmin,
};
