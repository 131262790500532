import React, { Fragment } from "react";

import Toggle from "../../utilities/Toggle";
import CreditCardForm from "../CreditCardForm";
import StripeCardForm from "../StripeCardForm";

class NewCardForm extends React.Component {
  state = {
    type: undefined,
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { handleSubmit, stripeAdd, handleLink } = this.props;
    return (
      <Toggle>
        {({ toggle, on }) => (
          <Fragment>
            <div className={`${on && "d-none"}`}>
              <button
                className={`btn btn-success btn-block`}
                onClick={e => toggle()}
              >
                Add Credit Card
              </button>
            </div>
            <div className={`${!on && "d-none"}`}>
              <h3>Add Credit Card To Account</h3>
              {stripeAdd ? (
                <Fragment>
                  <div className="form-check">
                    <input
                      className={`form-check-input`}
                      type="radio"
                      id="type--true"
                      name="type"
                      checked={this.state.type === "true"}
                      value={true}
                      onChange={this.handleChange}
                    />
                    <label className="form-check-label">Add New Card</label>
                  </div>
                  <div className="form-check">
                    <input
                      className={`form-check-input`}
                      type="radio"
                      id="type--false"
                      name="type"
                      checked={this.state.type === "false"}
                      value={false}
                      onChange={this.handleChange}
                    />
                    <label className="form-check-label">Link Stripe Card</label>
                  </div>

                  {this.state.type !== "" &&
                    typeof this.state.type !== "undefined" && (
                      <Fragment>
                        {this.state.type === "true" ? (
                          <CreditCardForm handleSubmit={handleSubmit} />
                        ) : (
                          <StripeCardForm handleSubmit={handleLink} />
                        )}
                      </Fragment>
                    )}
                </Fragment>
              ) : (
                <CreditCardForm handleSubmit={handleSubmit} />
              )}
            </div>
          </Fragment>
        )}
      </Toggle>
    );
  }
}

NewCardForm.defaultProps = {
  stripeAdd: false,
};

export default NewCardForm;
