import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DetailItem from "../DetailItem";

const ClientDetails = ({ data, admin = false }) => {
  return (
    <Fragment>
      {data.attributes.has_fail ? (
        <div className="alert alert-danger mt-4">
          <FontAwesomeIcon icon="exclamation-triangle" className="mr-2" />
          <strong>FAILED PAYMENTS SEE DOMAINS BELOW</strong>
        </div>
      ) : null}
      <div className="row mt-4">
        <div className="col-md">
          <DetailItem
            label="Client Name"
            data={`${data.attributes.first_name} ${data.attributes.last_name}`}
          />
        </div>
        <div className="col-md">
          <DetailItem label="Client Email" data={data.attributes.email} />
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-md">
          <DetailItem
            label="Number Domains"
            data={data.attributes.num_domains}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ClientDetails;
