import React, { Component } from "react";

class StripeCardForm extends Component {
  state = {
    card_id: "",
    required: ["card_id"],
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { required, ...inputValues } = this.state;

    // Add Credit Card
    this.props.handleSubmit(inputValues);
  };

  isInvalid = () => {
    const req = this.state.required.some(input => {
      return (
        this.state[input] === null ||
        this.state[input] === "" ||
        typeof this.state[input] === "undefined"
      );
    });

    // If any fields are empty
    if (req) {
      return true;
    }

    return false;
  };

  isRequired = name => this.props.required.includes(name);

  render() {
    return (
      <form className="mt-4" id="stripeCardForm" onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Stripe Card ID: *</label>
          <input
            type="text"
            className={`form-control`}
            placeholder="Stripe Card ID"
            name="card_id"
            id="card_id"
            onChange={this.handleChange}
            value={this.state.card_id}
          />
        </div>

        <button
          type="submit"
          className="btn btn-success btn-block mt-4"
          disabled={this.isInvalid()}
          form="stripeCardForm"
        >
          Link To Card
        </button>
      </form>
    );
  }
}

export default StripeCardForm;
