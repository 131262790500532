import React, { Component } from "react";
import { connect } from "react-redux";

import Layout from "../../components/modules/Layout";
import NewPasswordForm from "../../components/forms/NewPasswordForm";

import { authActions } from "../../actions";

class SettingsPage extends Component {
  handleSubmit = values => {
    this.props.dispatch(authActions.updatePassword(values));
  };

  render() {
    return (
      <Layout history={this.props.history}>
        <div className="row">
          <div className="col">
            <h3 className="card-title mb-1">Account Settings</h3>
            <p className="text-secondary">Update your password below</p>

            <NewPasswordForm handleSubmit={this.handleSubmit} />
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  account: state.account,
});

export default connect(mapStateToProps)(SettingsPage);
