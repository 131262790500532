import React, { Component } from "react";
import { connect } from "react-redux";

import { userServices } from "../../../services";

import Layout from "../../../components/modules/Layout";
import ClientDetails from "../../../components/modules/ClientDetails";
import DomainsTable from "../../../components/tables/DomainsTable";

class AccountPage extends Component {
  componentDidMount() {
    userServices.getUserInfo();
  }

  getDomains() {
    const { account } = this.props.account;
    const { data, included } = account;
    if (data.attributes.num_domains <= 0) {
      return [];
    }

    return data.relationships.domains.data.map(domain =>
      included.find(rel => rel.type === domain.type && rel.id === domain.id),
    );
  }

  render() {
    const { user } = this.props.app;
    const { account } = this.props.account;
    return (
      <Layout history={this.props.history}>
        <div className="row">
          <div className="col">
            <h3 className="card-title mb-1">
              {user.first_name}'s Account Details
            </h3>
            <p className="text-secondary">View your account details below</p>
          </div>
        </div>
        {account.data && <ClientDetails data={account.data} />}

        <div className="mt-4">
          <h3>Your Domain{account.data.attributes.num_domains > 1 && `s`}</h3>
          <DomainsTable domains={this.getDomains()} />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
  account: state.account,
});

export default connect(mapStateToProps)(AccountPage);
