import React from "react";

import { authActions } from "../../../actions";

import logo from "../../../imgs/full-logo.png";

const logOut = ({ dispatch }) => () => dispatch(authActions.logout());

const Header = ({ isLoggedIn, dispatch }) => (
  <header>
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <div className="col-6 logo-container">
          <img src={logo} alt="FrontWard Client Manager" />
        </div>
        {isLoggedIn && (
          <div className="col-6 text-right">
            <button
              className="btn btn-danger"
              onClick={logOut({ dispatch })}
              title="Log Out"
            >
              Log-Out
            </button>
          </div>
        )}
      </div>
    </nav>
  </header>
);

export default Header;
