import React, { Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { routeConstants } from "../../../constants";
import { userServices } from "../../../services";

const Sidebar = ({ app }) => {
  return (
    <div className="p-4 sidebar-container">
      <ul className="nav flex-column nav-pills">
        {userServices.isAdmin(app.user) ? (
          <Fragment>
            <li className="nav-item">
              <NavLink
                exact
                to={routeConstants.CLIENTS}
                activeClassName="active"
                className="nav-link"
              >
                Clients
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to={routeConstants.NEW_CLIENT}
                activeClassName="active"
                className="nav-link"
              >
                New Client
              </NavLink>
            </li>
          </Fragment>
        ) : (
          <Fragment>
            <li className="nav-item">
              <NavLink
                exact
                to={routeConstants.ACCOUNT}
                activeClassName="active"
                className="nav-link"
              >
                My Account
              </NavLink>
            </li>
          </Fragment>
        )}
        <li className="nav-item">
          <NavLink
            to={routeConstants.ACCOUNT_SETTING}
            activeClassName="active"
            className="nav-link"
          >
            Account Settings
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = state => ({
  app: state.app,
});

export default connect(mapStateToProps)(Sidebar);
