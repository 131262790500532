import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { routeConstants } from "../../../constants";
import { userServices } from "../../../services";
import PrivateRoute from "../PrivateRoute";

const AdminRoute = ({ app, ...rest }) => {
  return userServices.isAdmin(app.user) ? (
    <PrivateRoute {...rest} />
  ) : (
    <Redirect to={routeConstants.ACCOUNT} />
  );
};

AdminRoute.propTypes = {
  app: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  app: state.app,
});

export default connect(mapStateToProps)(AdminRoute);
