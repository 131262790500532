import React, { Component } from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import rootReducer from "./reducers";
import rootSaga from "./sagas";

import AppRouter from "./config/AppRouter";

const sagaMiddleware = createSagaMiddleware(),
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose,
  history = createBrowserHistory(),
  store = createStore(
    rootReducer(history),
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware,
        //logger
      ),
    ),
  );

sagaMiddleware.run(rootSaga);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <AppRouter history={history} />
      </Provider>
    );
  }
}

export default App;
