import { accountConstants } from "../constants";

const initState = {
  account: [],
  domain: [],
};

export default function account(state = initState, action) {
  switch (action.type) {
    case accountConstants.STORE_ACCOUNT_DOMAIN:
      return {
        ...state,
        domain: action.payload,
      };

    case accountConstants.STORE_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };

    case accountConstants.UPDATE_CARD_ID:
      return {
        ...state,
        account: {
          ...state.account,
          attributes: {
            ...state.account.attributes,
            card_id: action.payload,
          },
        },
      };

    default:
      return state;
  }
}
