import React, { Fragment } from "react";

import ClientRow from "../../table-rows/ClientRow";
import Table from "../../utilities/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ClientsTable extends React.Component {
  state = {
    clients: [],
    filter: undefined,
  };

  componentWillReceiveProps(newProps) {
    if (newProps.client.clients.length !== this.state.clients) {
      this.setState({
        clients: newProps.client.clients,
      });
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.filterResults(e.target.value);
  };

  filterResults = (val = null) => {
    const allClients = this.props.client.clients;
    if (val === null || val === "") {
      this.setState({
        clients: allClients,
      });
    }

    // Format String
    val = val.toLowerCase();
    const filtered = allClients.filter(client => {
      const fname = client.attributes.first_name.toLowerCase(),
        lname = client.attributes.last_name.toLowerCase(),
        domain = client.attributes.sample_domain.toLowerCase();

      if (fname.includes(val) || lname.includes(val) || domain.includes(val)) {
        return true;
      }
    });

    this.setState({
      clients: filtered,
    });
  };

  renderRow = ({ node, key }) => <ClientRow client={node} key={key} />;

  renderFilter = () => (
    <div className="input-group">
      <input
        type="text"
        name="filter"
        className="form-control"
        placeholder="Search Clients..."
        onChange={this.handleChange}
        value={this.state.filter}
      />
      <div className="input-group-append">
        <span className="input-group-text">
          <FontAwesomeIcon icon="search" />
        </span>
      </div>
    </div>
  );

  render() {
    return (
      <Fragment>
        <Table
          renderRow={this.renderRow}
          renderFilter={this.renderFilter}
          label="Clients"
          data={this.state.clients}
          numCols={2}
        />
      </Fragment>
    );
  }
}

export default ClientsTable;
