import React, { Fragment } from "react";

import DomainRow from "../../table-rows/DomainRow";
import Table from "../../utilities/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DomainsTable extends React.Component {
  state = {
    domains: [],
    filter: undefined,
  };

  componentDidMount() {
    this.setState({
      domains: this.props.domains,
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.filterResults(e.target.value);
  };

  filterResults = (val = null) => {
    const allDomains = this.props.domains;
    if (val === null || val === "") {
      this.setState({
        clients: allDomains,
      });
    }

    // Format String
    val = val.toLowerCase();
    const filtered = allDomains.filter(domain => {
      const name = domain.attributes.domain.toLowerCase();
      if (name.includes(val)) {
        return true;
      }
    });

    this.setState({
      domains: filtered,
    });
  };

  renderRow = ({ node, key }) => (
    <DomainRow domain={node} key={key} admin={this.props.admin} />
  );

  renderFilter = () => (
    <div className="input-group">
      <input
        type="text"
        name="filter"
        className="form-control"
        placeholder="Search Domains..."
        onChange={this.handleChange}
        value={this.state.filter}
      />
      <div className="input-group-append">
        <span className="input-group-text">
          <FontAwesomeIcon icon="search" />
        </span>
      </div>
    </div>
  );

  render() {
    return (
      <Fragment>
        <Table
          renderRow={this.renderRow}
          renderFilter={this.renderFilter}
          label="Domains"
          data={this.state.domains}
          numCols={2}
        />
      </Fragment>
    );
  }
}

DomainsTable.defaultProps = {
  admin: false,
};

export default DomainsTable;
