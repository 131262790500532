import { domainConstants } from "../constants";

/**
 * Get Domain
 */
const getDomain = id => ({
  type: domainConstants.GET_DOMAIN,
  payload: id,
});

/**
 * Create New Domain
 */
const createDomain = data => ({
  type: domainConstants.CREATE_DOMAIN,
  payload: data,
});

/**
 * Delete Domain
 */
const deleteDomain = id => ({
  type: domainConstants.DELETE_DOMAIN,
  payload: id,
});

/**
 * Update A Client
 */
const updateDomain = data => ({
  type: domainConstants.UPDATE_DOMAIN,
  payload: data,
});

export const domainActions = {
  createDomain,
  deleteDomain,
  updateDomain,
  getDomain,
};
