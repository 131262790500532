import React, { Component } from "react";
import { connect } from "react-redux";

import { domainActions } from "../../../actions";

class ManageDomainForm extends Component {
  state = {
    domain: "",
    setup_stripe: "",
    stripe_id: "",
    has_email: "",
    num_emails: "",
    required: ["domain", "has_email", "setup_stripe"],
    touched: [],
  };

  handleChange = e => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
    });

    // Has Email Or Not
    if (
      e.currentTarget.name === "has_email" &&
      e.currentTarget.value === "false"
    ) {
      this.setState({
        num_emails: null,
      });
    }

    // If Client Was Setup In Stripe Previously
    if (e.currentTarget.name === "setup_stripe") {
      if (e.currentTarget.value === "true") {
        this.setState({
          required: [...this.state.required, "stripe_id"],
        });
      } else {
        const required = this.state.required.filter(vl => vl !== "stripe_id");
        this.setState({
          required,
          stripe_id: null,
        });
      }
    }
  };

  handleBlur = e => {
    const name = e.target.name;
    this.setState({
      touched: [...this.state.touched, name],
    });
  };

  isInvalid = () => {
    const empty = this.state.required.some(
      v =>
        this.state[v] === null ||
        this.state[v] === "" ||
        typeof this.state[v] === "undefined",
    );

    if (empty) {
      return empty;
    }
    if (!this.validURL(this.state.domain)) {
      return true;
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    // Get Values Besides Required
    const { required, touched, ...values } = this.state;
    const { id } = this.props;
    values.has_email = values.has_email === "true" ? true : false;
    values.setup_stripe = values.setup_stripe === "true" ? true : false;
    values.domain = this.removeUrlProtocol(values.domain);
    this.props.dispatch(domainActions.createDomain({ data: values, id }));
  };

  removeUrlProtocol = url => url.replace(/^(?:https?:\/\/)?(?:www\.)?/, "");

  validURL = url => {
    if (url === "" || typeof url === "undefined") {
      return true;
    }
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i",
    );
    return !!pattern.test(url);
  };

  isRequired = name => this.state.required.includes(name);

  invalidClass = name =>
    this.state[name] == "" &&
    this.isRequired(name) &&
    this.state.touched.includes(name)
      ? true
      : false;

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-md">
            <div className="form-group">
              <label>Domain *</label>
              <input
                type="text"
                className={`form-control ${(this.invalidClass("domain") ||
                  !this.validURL(this.state.domain)) &&
                  "is-invalid"}`}
                name="domain"
                value={this.state.domain}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              {(this.invalidClass("domain") ||
                !this.validURL(this.state.domain)) && (
                <div className="invalid-feedback">Please enter valid url</div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md mb-2">
            <label>Was Client Domain Previously Setup In Stripe? *</label>
            <div className="form-check">
              <input
                className={`form-check-input`}
                type="radio"
                id="setup_stripe--true"
                name="setup_stripe"
                checked={this.state.setup_stripe === "true"}
                value={true}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              <label className="form-check-label">Yes</label>
            </div>
            <div className="form-check">
              <input
                className={`form-check-input`}
                type="radio"
                id="setup_stripe--false"
                name="setup_stripe"
                checked={this.state.setup_stripe === "false"}
                value={false}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              <label className="form-check-label">No</label>
            </div>
          </div>
          {this.state.setup_stripe === "true" && (
            <div className="col-md">
              <div className="form-group">
                <label>Stripe ID *</label>
                <input
                  type="text"
                  className={`form-control ${this.invalidClass("stripe_id") &&
                    "is-invalid"}`}
                  name="stripe_id"
                  value={this.state.stripe_id}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
                {this.invalidClass("stripe_id") && (
                  <div className="invalid-feedback">This field is required</div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md">
            <label>Does Client Need Emails? *</label>
            <div className="form-check">
              <input
                className={`form-check-input`}
                type="radio"
                id="has_email--true"
                name="has_email"
                checked={this.state.has_email === "true"}
                value={true}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              <label className="form-check-label">Has Email(s)</label>
            </div>
            <div className="form-check">
              <input
                className={`form-check-input`}
                type="radio"
                id="has_email--false"
                name="has_email"
                checked={this.state.has_email === "false"}
                value={false}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              <label className="form-check-label">No Email(s)</label>
            </div>
          </div>
          {this.state.has_email === "true" && (
            <div className="col-md">
              <div className="form-group">
                <label>Number Of Emails</label>
                <input
                  type="text"
                  className={`form-control`}
                  name="num_emails"
                  value={this.state.num_emails}
                  defaultValue={""}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
              </div>
            </div>
          )}
        </div>

        <button
          className="btn btn-success btn-block mt-4"
          disabled={this.isInvalid()}
          type="submit"
        >
          Create Domain
        </button>
      </form>
    );
  }
}

export default connect(null)(ManageDomainForm);
