import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Layout from "../../../components/modules/Layout";
import Toggle from "../../../components/utilities/Toggle";
import ForgotPasswordModal from "../../../components/modals/ForgotPasswordModal";
import LoginForm from "../../../components/forms/LoginForm";

import { routeConstants } from "../../../constants";

class LoginPage extends Component {
  componentDidMount() {
    if (this.props.auth.isLoggedIn === true) {
      this.props.history.push(routeConstants.CLIENTS);
    }
  }

  render() {
    return (
      <Layout history={this.props.history} hideSidebar={true}>
        <h3>Frontward Admin Login</h3>
        <em className="text-secondary">
          Please fill out your account credentials below:
        </em>

        <LoginForm />

        <div className="text-right">
          <Toggle>
            {({ on, toggle }) => (
              <Fragment>
                <ForgotPasswordModal on={on} toggle={toggle} />
                <a href="#" className="d-block mt-3" onClick={e => toggle()}>
                  Forgot Password?
                </a>
              </Fragment>
            )}
          </Toggle>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(LoginPage);
