import React, { Component } from "react";
import { connect } from "react-redux";

import { clientActions } from "../../../actions";

class ManageClientForm extends Component {
  state = {
    first_name: "",
    last_name: "",
    required: ["first_name", "last_name"],
  };

  componentDidMount() {
    const user = this.props.user.attributes;
    this.setState({
      first_name: user.first_name,
      last_name: user.last_name,
    });
  }

  handleChange = e => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  isInvalid = () => {
    const empty = this.state.required.some(
      v =>
        this.state[v] === null ||
        this.state[v] === "" ||
        typeof this.state[v] === "undefined",
    );
    if (empty) {
      return empty;
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    // Get Values Besides Required
    const { required, ...data } = this.state;
    const id = this.props.user.id;
    this.props.dispatch(clientActions.updateClient({ data, id }));
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-md">
            <div className="form-group">
              <label>First Name *</label>
              <input
                type="text"
                className={`form-control ${this.state.first_name === "" &&
                  "is-invalid"}`}
                name="first_name"
                value={this.state.first_name}
                onChange={this.handleChange}
              />
              {this.state.first_name === "" && (
                <div className="invalid-feedback">This field is required</div>
              )}
            </div>
          </div>
          <div className="col-md">
            <div className="form-group">
              <label>Last Name *</label>
              <input
                type="text"
                className={`form-control ${this.state.last_name === "" &&
                  "is-invalid"}`}
                name="last_name"
                value={this.state.last_name}
                onChange={this.handleChange}
              />
              {this.state.last_name === "" && (
                <div className="invalid-feedback">This field is required</div>
              )}
            </div>
          </div>
        </div>

        <button
          className="btn btn-success btn-block mt-4"
          disabled={this.isInvalid()}
          type="submit"
        >
          Update Client
        </button>
      </form>
    );
  }
}

export default connect(null)(ManageClientForm);
