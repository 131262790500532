import { authConstants } from "../constants";

/**
 * Function to logout users
 */
const logout = () => ({ type: authConstants.ACCOUNT_LOGOUT });

/**
 * Function to log user in
 */
const login = ({ email, password }) => ({
  type: authConstants.ACCOUNT_LOGIN,
  payload: { email, password },
});

/**
 * Reset Password
 */
const resetPassword = data => ({
  type: authConstants.RESET_PASSWORD,
  payload: data,
});

/**
 * Forgot Password
 */
const forgotPassword = ({ email }) => ({
  type: authConstants.FORGOT_PASSWORD,
  payload: email,
});

/**
 * Verify Token
 */
const verifyToken = token => ({
  type: authConstants.VERIFY_FORGOT_TOKEN,
  payload: token,
});

/**
 * Set Token Based On localStorage
 */
const setToken = token => ({
  type: authConstants.ACCOUNT_LOGIN,
  payload: { token },
});

/**
 * Update Password
 */
const updatePassword = data => ({
  type: authConstants.UPDATE_PASSWORD,
  payload: data,
});

export const authActions = {
  logout,
  login,
  setToken,
  forgotPassword,
  verifyToken,
  resetPassword,
  updatePassword,
};
