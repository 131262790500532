import { put, call, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";

import {
  domainConstants,
  errorConstants,
  appConstants,
  routeConstants,
} from "../constants";
import { apiServices } from "../services";

import { request } from "./appSaga";

function* getDomain(action) {
  const res = yield call(request, {
    fn: apiServices.getDomain,
    params: action.payload,
  });
  if (res) {
    yield put({
      type: domainConstants.STORE_DOMAIN,
      payload: res,
    });
  }
}

function* createDomain(action) {
  const res = yield call(request, {
    fn: apiServices.createDomain,
    params: action.payload,
  });
  if (res) {
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Domain Created`,
        message: "Successfully added new domain",
        type: "success",
      },
    });
    yield put(push(`/domain/${res.data.id}`));
  }
}

function* updateDomain(action) {
  const res = yield call(request, {
    fn: apiServices.updateDomain,
    params: action.payload,
  });
  if (res) {
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Domain Details Updated`,
        message: `Domain has been updated in database`,
        type: "success",
      },
    });
  }
}

function* deleteDomain(action) {
  const res = yield call(request, {
    fn: apiServices.deleteDomain,
    params: action.payload,
  });
  if (res) {
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Domain Deleted`,
        message: `Domain has been deleted from database`,
        type: "success",
      },
    });
    yield put(push(routeConstants.CLIENTS));
  }
}

export default function* domainSaga() {
  yield takeLatest(domainConstants.CREATE_DOMAIN, createDomain);
  yield takeLatest(domainConstants.UPDATE_DOMAIN, updateDomain);
  yield takeLatest(domainConstants.DELETE_DOMAIN, deleteDomain);
  yield takeLatest(domainConstants.GET_DOMAIN, getDomain);
}
