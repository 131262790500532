import React from "react";
import TableHeader from "../TableHeader";

const renderClass = classes => `table ${classes}`;

const Table = ({
  data = [],
  headers = [],
  renderRow,
  classes = "",
  numCols = 0,
  label = "Items",
  renderFilter,
}) => (
  <div className="table-module">
    <div className="table-container">
      <table className={renderClass(classes)}>
        <tbody>
          <tr className="table_filter">
            <td colSpan={numCols}>{renderFilter()}</td>
          </tr>
          <tr className="table_th table_th--heading">
            {// Rendering Header
            headers.map((header, key) => (
              <TableHeader title={header.title} key={key} />
            ))}
          </tr>
          {data.length <= 0 ? (
            <tr>
              <td colSpan={numCols} className="text-center">
                <span className="noneloading">No {label}</span>
              </td>
            </tr>
          ) : (
            data.map((node, key) => renderRow({ node, key }))
          )}
        </tbody>
      </table>
    </div>
  </div>
);

export default Table;
