import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const DomainRow = ({ domain, admin = false }) => (
  <tr>
    <td>
      <div className="row">
        <div className="col-md-auto">
          <small
            className={`mr-2 ${
              domain.attributes.card_id &&
              !domain.attributes.last_payment_failed
                ? "text-success"
                : "text-danger"
            } ${domain.attributes.last_payment_failed && `text-danger`}`}
          >
            {domain.attributes.last_payment_failed ? (
              <span>
                <FontAwesomeIcon icon="times-circle" className="mr-2" />
                Card Fail
              </span>
            ) : (
              <Fragment>
                {domain.attributes.card_id ? (
                  <span>
                    <FontAwesomeIcon icon="check-circle" className="mr-2" />
                    Has Card
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon icon="times-circle" className="mr-2" />
                    No Card
                  </span>
                )}
              </Fragment>
            )}
          </small>
        </div>
        <div className="col-md">
          {domain.attributes.domain}
          <div>
            <FontAwesomeIcon icon="envelope" className="mr-2" />
            {domain.attributes.has_email ? (
              <span>{domain.attributes.num_emails}</span>
            ) : (
              <em>None</em>
            )}
          </div>
        </div>
      </div>
    </td>
    <td className="text-right">
      <div className="btn-group">
        {admin ? (
          <Fragment>
            <Link
              className="btn btn-primary"
              to={`/domain/${domain.id}`}
              title="Domain Details"
            >
              <FontAwesomeIcon icon="globe" />
            </Link>
            <Link
              className="btn btn-warning"
              to={`/manage-domain/${domain.id}`}
              title="Manage Domain"
            >
              <FontAwesomeIcon icon="pen" />
            </Link>
          </Fragment>
        ) : (
          <Link
            className="btn btn-success"
            to={`/manage-card/${domain.id}`}
            title="Manage Domain CC"
          >
            <FontAwesomeIcon icon="credit-card" />
          </Link>
        )}
      </div>
    </td>
  </tr>
);

export default DomainRow;
