import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Layout from "../../../components/modules/Layout";
import DomainDetails from "../../../components/modules/DomainDetails";

import { routeConstants } from "../../../constants";
import { apiServices } from "../../../services/api.services";

class ViewDomainPage extends Component {
  state = {
    currentDomain: [],
    relationships: [],
  };

  async componentDidMount() {
    this.getDomainInfo();
  }

  getDomainInfo = async () => {
    const id = this.props.match.params.id;
    const domain = await apiServices.getDomain(id);
    this.setState({
      currentDomain: domain.data,
    });
  };

  render() {
    const id = this.props.match.params.id;
    const { currentDomain } = this.state;
    return (
      <Layout history={this.props.history}>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={routeConstants.CLIENTS}>Frontward Clients</Link>
            </li>
            {currentDomain.attributes && (
              <li className="breadcrumb-item">
                <Link
                  to={`/client/${currentDomain.relationships.client.data.id}`}
                >
                  Client Details
                </Link>
              </li>
            )}
            <li className="breadcrumb-item active">Domain Details</li>
          </ol>
        </nav>

        {currentDomain && currentDomain.attributes && (
          <Fragment>
            <div className="row">
              <div className="col">
                <h1 className="d-inline">Domain Details</h1>
                <span
                  className={`ml-3 badge ${
                    currentDomain.attributes.card_id
                      ? "badge-success"
                      : "badge-danger"
                  }`}
                >
                  {currentDomain.attributes.card_id ? (
                    <Fragment>Has Card</Fragment>
                  ) : (
                    <Fragment>No Card</Fragment>
                  )}
                </span>
              </div>
              <div className="col-auto">
                <div className="btn-group">
                  <Link
                    to={`/manage-domain/${id}`}
                    className="btn btn-primary"
                    title="Manage Domain Details"
                  >
                    <FontAwesomeIcon icon="pen" />
                  </Link>
                  <Link
                    className="btn btn-success"
                    to={`/manage-client-card/${id}`}
                    title="Manage Client CC"
                  >
                    <FontAwesomeIcon icon="credit-card" />
                  </Link>
                </div>
              </div>
            </div>

            <DomainDetails data={currentDomain} admin={true} />
          </Fragment>
        )}
      </Layout>
    );
  }
}

export default connect(null)(ViewDomainPage);
