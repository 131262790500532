import React, { Component } from "react";
import { connect } from "react-redux";

import Toast from "../Toast";

import { appActions } from "../../../actions";

class Toastr extends Component {
  removeToast = ix => {
    this.props.dispatch(appActions.removeToast(ix));
  };

  render() {
    const { toasts } = this.props.app;
    if (toasts.length <= 0) {
      return null;
    }

    return (
      <div className="toastr">
        <div className="toast_container">
          {toasts.map((toast, ix) => (
            <Toast
              key={ix}
              title={toast.title}
              type={toast.type}
              removeToast={() => this.removeToast(ix)}
            >
              {toast.message}
            </Toast>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
});

export default connect(mapStateToProps)(Toastr);
