import API from "../config/API";
import * as Sentry from "@sentry/browser";

API.interceptors.response.use(
  response => {
    console.log({ response });
    return response;
  },
  error => {
    if (error.response.status !== 401) {
      Sentry.captureException(error);
      Sentry.captureMessage(JSON.stringify(error.request));
    }
    return Promise.reject(error);
  }
);

/**
 * Set Token Header
 * @param {string} token
 */
const setAuthHeader = token => {
  API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

/**
 * Remove Token Header
 */
const removeAuthHeader = () => {
  delete API.defaults.headers.common["Authorization"];
};

/**
 * Log User In
 * @param {array} data
 */
const login = (data = {}) => API.post(`/login`, { ...data }).then(returnJSON);

/**
 * Forgot Password
 */
const forgotPass = (data = {}) =>
  API.put("/forgot-password", { ...data }).then(returnJSON);

/**
 * Verify Forgot Token
 */
const verifyForgotToken = token =>
  API.get(`/forgot-verify/${token}`).then(returnJSON);

/**
 * Reset Password
 */
const resetPass = (data = {}) =>
  API.put("/reset-password", { ...data }).then(returnJSON);

/**
 * Update Password
 */
const updatePass = (data = {}) =>
  API.put("/update-password", { ...data }).then(returnJSON);

/**
 * Get Clients
 */
const getClients = (params = {}) =>
  API.get(`/admin/client`, { ...params }).then(returnJSON);

/**
 * Get Client by id
 */
const getClient = id => API.get(`/admin/client/${id}`).then(returnJSON);

/**
 * Create Client
 */
const createClient = (data = {}) =>
  API.post(`/admin/client`, { ...data }).then(returnJSON);

/**
 * Update Client
 */
const updateClient = ({ data, id }) =>
  API.put(`/admin/client/${id}`, { ...data }).then(returnJSON);

/**
 * Delete Client
 */
const deleteClient = id => API.delete(`/admin/client/${id}`).then(returnJSON);

/**
 * Get Domain
 */
const getDomain = id => API.get(`/admin/domain/${id}`).then(returnJSON);

/**
 * Get Client Domain
 */
const getClientDomain = id => API.get(`/domain/${id}`).then(returnJSON);

/**
 * Create Domain
 */
const createDomain = ({ data, id }) =>
  API.post(`/admin/client/${id}/domain`, { ...data }).then(returnJSON);

/**
 * Create Domain
 */
const updateDomain = ({ data, id }) =>
  API.put(`/admin/domain/${id}`, { ...data }).then(returnJSON);

/**
 * Delete Domain
 */
const deleteDomain = id => API.delete(`/admin/domain/${id}`).then(returnJSON);

/**
 * Get Account
 */
const getAccount = id => API.get(`/account`).then(returnJSON);

/**
 * Get Account
 */
const updateAccount = id => API.put(`/account`).then(returnJSON);

/**
 * Return Response Object Back
 * @param {object} res
 */
const returnJSON = res => Promise.resolve(res.data);

/**
 * Get Card
 */
const adminGetCard = id => API.get(`/admin/domain/${id}/card`).then(returnJSON);

/**
 * Add Card
 */
const adminAddCard = ({ id, data }) =>
  API.post(`/admin/domain/${id}/card`, { ...data }).then(returnJSON);

/**
 * Update Card
 */
const adminUpdateCard = ({ id, data }) =>
  API.put(`/admin/domain/${id}/card`, { ...data }).then(returnJSON);

/**
 * Delete Card
 */
const adminDeleteCard = id =>
  API.delete(`/admin/domain/${id}/card`).then(returnJSON);

/**
 * Get Card
 */
const getCard = id => API.get(`/domain/${id}/card`).then(returnJSON);

/**
 * Add Card
 */
const addCard = ({ data, id }) =>
  API.post(`/domain/${id}/card`, { ...data }).then(returnJSON);

/**
 * Update Card
 */
const updateCard = ({ data, id }) =>
  API.put(`/domain/${id}/card`, { ...data }).then(returnJSON);

/**
 * Delete Card
 */
const deleteCard = id => API.delete(`/domain/${id}/card`).then(returnJSON);

/**
 * Link Stripe Card
 */
const linkCard = ({ data, id }) =>
  API.post(`/admin/domain/${id}/stripe-card`, { ...data }).then(returnJSON);

export const apiServices = {
  setAuthHeader,
  removeAuthHeader,
  login,
  createClient,
  updateClient,
  deleteClient,
  getClients,
  getClient,
  getAccount,
  updateAccount,
  adminGetCard,
  adminUpdateCard,
  adminDeleteCard,
  adminAddCard,
  addCard,
  updateCard,
  getCard,
  deleteCard,
  forgotPass,
  verifyForgotToken,
  resetPass,
  updatePass,
  linkCard,
  getDomain,
  createDomain,
  updateDomain,
  deleteDomain,
  getClientDomain
};
