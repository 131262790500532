const API_BASE = "https://client.frontward.com/api",
  VERSION = 1;

export const apiConstants = {
  API_BASE: API_BASE,
  VERSION: VERSION,
  AUTH_TOKEN: `frontward-token`,
  PAGE_LIMIT: 20,
  API_URL: `${API_BASE}/v${VERSION}/`,
};
