import React, { Component } from "react";

import Layout from "../../../components/modules/Layout";
import NewClientForm from "../../../components/forms/NewClientForm";

class NewClientPage extends Component {
  render() {
    return (
      <Layout history={this.props.history}>
        <h1>Setup New Client With Frontward</h1>
        <p>Please enter client details below</p>
        <NewClientForm />
      </Layout>
    );
  }
}

export default NewClientPage;
