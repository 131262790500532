import { clientConstants } from "../constants";

/**
 * Create New Client
 */
const createClient = data => ({
  type: clientConstants.CREATE_CLIENT,
  payload: data,
});

/**
 * Get A Client
 */
const getClient = id => ({
  type: clientConstants.GET_CLIENT,
  payload: id,
});

/**
 * Get A Client
 */
const getClients = params => ({
  type: clientConstants.GET_CLIENTS,
  payload: params,
});

/**
 * Delete Client
 */
const deleteClient = id => ({
  type: clientConstants.DELETE_CLIENT,
  payload: id,
});

/**
 * Update A Client
 */
const updateClient = data => ({
  type: clientConstants.UPDATE_CLIENT,
  payload: data,
});

/**
 * Create Client Card
 */
const createCard = data => ({
  type: clientConstants.CREATE_CLIENT_CARD,
  payload: data,
});

/**
 * Update Client Card
 */
const updateCard = data => ({
  type: clientConstants.UPDATE_CLIENT_CARD,
  payload: data,
});

/**
 * Delete Client Card
 */
const deleteCard = id => ({
  type: clientConstants.DELETE_CLIENT_CARD,
  payload: id,
});

/**
 * Get Client Card
 */
const getCard = id => ({
  type: clientConstants.GET_CLIENT_CARD,
  payload: id,
});

/**
 * Add Stripe Card
 */
const linkCard = data => ({
  type: clientConstants.LINK_STRIPE_CARD,
  payload: data,
});

export const clientActions = {
  createClient,
  updateClient,
  getClient,
  getClients,
  deleteClient,
  getCard,
  deleteCard,
  updateCard,
  createCard,
  linkCard,
};
