import React from "react";

import logo from "../../../imgs/logo.png";

const Spinner = () => (
  <div>
    <div className="loading-screen">
      <div className="container text-center">
        <div className="loading-screen-container">
          <div className="loading-screen-logo">
            <img src={logo} className="loading-logo" />
          </div>
          <div className="loading-screen-spinner">
            <i className="fas fa-spinner fa-pulse" />
          </div>
          <div className="loading-screen-text">
            <span className="loading-text">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Spinner;
