import React, { Component } from "react";
import { connect } from "react-redux";

import { authActions } from "../../../actions";

class LoginForm extends Component {
  state = {
    email: "",
    password: "",
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.dispatch(authActions.login(this.state));
  };

  isInvalid = () => {
    if (
      this.state.email === "" ||
      this.state.email === null ||
      this.state.password === "" ||
      this.state.password === null
    ) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <form className="mt-4" id="loginForm" onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            className="form-control"
            placeholder="Email Address"
            name="email"
            id="email"
            onChange={this.handleChange}
            value={this.state.email}
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            name="password"
            id="password"
            onChange={this.handleChange}
            value={this.state.password}
          />
        </div>

        <button
          type="submit"
          className="btn btn-success btn-block"
          disabled={this.isInvalid()}
          form="loginForm"
        >
          Login Account
        </button>
      </form>
    );
  }
}

export default connect(null)(LoginForm);
