import React from "react";

const renderClass = active => {
  const activeClass = active ? `table_heading--active` : "";
  return `table_heading ${activeClass}`;
};

// const directionIcon = direction => (direction ? "chevron-up" : "chevron-down");

const TableHeader = ({ title, direction = false, active = false }) => (
  <th className={renderClass(active)}>
    <div className="table_heading_content">
      {/*active ? <FontAwesomeIcon icon={directionIcon(direction)} /> : ""*/}
      <span className="table_heading_title">{title}</span>
    </div>
  </th>
);

export default TableHeader;
