import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { routeConstants } from "../../../constants";

const Footer = ({ isLoggedIn }) => (
  <footer>
    <div className="container">
      <nav className="nav-footer">
        {!isLoggedIn && <Link to={routeConstants.LOGIN}>Frontward Login</Link>}
        <a href="http://frontward.com" target="_blank">
          Frontward Web Hosting
        </a>
        {isLoggedIn && (
          <Fragment>
            <Link to={routeConstants.CLIENTS}>Frontward Clients</Link>
          </Fragment>
        )}
      </nav>
    </div>
  </footer>
);

export default Footer;
