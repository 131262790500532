import React, { Component } from "react";

import * as moment from "moment";

class CreditCardForm extends Component {
  state = {
    name: "",
    number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
    touched: [],
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleBlur = e => {
    const name = e.target.name;
    this.setState({
      touched: [...this.state.touched, name],
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { required, touched, ...inputValues } = this.state;

    // Add Credit Card
    this.props.handleSubmit(inputValues);
  };

  isInvalid = () => {
    const req = this.props.required.some(input => {
      return (
        this.state[input] === null ||
        this.state[input] === "" ||
        typeof this.state[input] === "undefined"
      );
    });

    // If any fields are empty
    if (req) {
      return true;
    }

    return false;
  };

  getYears = () => {
    const years = [
      {
        label: "Expiration Year",
        value: "",
      },
    ];
    for (let i = 0; i <= 20; i++) {
      const date = moment().add(i, "y");
      const yearValue = date.format("YY"),
        yearLabel = date.format("YYYY");

      years.push({ value: yearValue, label: yearLabel });
    }
    return years;
  };

  isRequired = name => this.props.required.includes(name);

  invalidClass = name =>
    this.state[name] == "" &&
    this.isRequired(name) &&
    this.state.touched.includes(name)
      ? true
      : false;

  isHidden = name => this.props.hidden.some(v => v === name);

  render() {
    return (
      <form className="mt-4" id="creditCardForm" onSubmit={this.handleSubmit}>
        {!this.isHidden("name") && (
          <div className="form-group">
            <label htmlFor="name">
              Name On Card: {this.isRequired("name") && `*`}
            </label>
            <input
              type="text"
              className={`form-control ${this.invalidClass("name") &&
                "is-invalid"}`}
              placeholder="Name On Card"
              name="name"
              id="name"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={this.state.name}
            />
          </div>
        )}

        {!this.isHidden("number") && (
          <div className="form-group">
            <label htmlFor="number">
              Card Number: {this.isRequired("number") && `*`}
            </label>
            <input
              type="text"
              className={`form-control ${this.invalidClass("number") &&
                "is-invalid"}`}
              placeholder="Credit Card Number"
              name="number"
              id="number"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={this.state.number}
            />
          </div>
        )}
        <div className="row">
          {!this.isHidden("exp_month") && (
            <div className="col-md">
              <div className="form-group">
                <label htmlFor="exp_month">
                  Expiration Month: {this.isRequired("exp_month") && `*`}
                </label>
                <select
                  className={`custom-select ${this.invalidClass("exp_month") &&
                    "is-invalid"}`}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="exp_month"
                  value={this.state.exp_month}
                  defaultValue=""
                >
                  <option value="">Expiration Month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">Sepetember</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
            </div>
          )}

          {!this.isHidden("exp_year") && (
            <div className="col-md">
              <div className="form-group">
                <label htmlFor="exp_year">
                  Expiration Year: {this.isRequired("exp_year") && `*`}
                </label>
                <select
                  className={`custom-select ${this.invalidClass("exp_year") &&
                    "is-invalid"}`}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="exp_year"
                  value={this.state.exp_year}
                  defaultValue=""
                >
                  {this.getYears().map((y, ix) => (
                    <option value={y.value} key={ix}>
                      {y.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {!this.isHidden("cvc") && (
            <div className="col-md">
              <div className="form-group">
                <label htmlFor="cvc">
                  Security Code: {this.isRequired("cvc") && `*`}
                </label>
                <input
                  type="text"
                  className={`form-control ${this.invalidClass("cvc") &&
                    "is-invalid"}`}
                  placeholder="CVC Number"
                  name="cvc"
                  id="cvc"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  value={this.state.cvc}
                />
              </div>
            </div>
          )}
        </div>

        <button
          type="submit"
          className="btn btn-success btn-block mt-4"
          disabled={this.isInvalid()}
          form="creditCardForm"
        >
          {this.props.submitText}
        </button>
      </form>
    );
  }
}

CreditCardForm.defaultProps = {
  submitText: "Add Client Credit Card",
  required: ["name", "number", "exp_month", "exp_year", "cvc"],
  hidden: [],
  handleSubmit: inputs => {
    console.log(inputs);
  },
};

export default CreditCardForm;
