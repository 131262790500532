import React, { Fragment } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Toggle from "../../utilities/Toggle";
import CreditCardForm from "../../forms/CreditCardForm";
import ConfirmModal from "../../modals/ConfirmModal";

class ManageCardForm extends React.Component {
  componentDidMount() {
    const { currentCard, cardID } = this.props;
    // No Attributes
    if (!currentCard.attributes || cardID !== currentCard.data.id) {
      // Loading Card
      this.props.loadCard();
    }
  }

  render() {
    const { deleteConfirm, handleSubmit, currentCard } = this.props;
    const cardInfo = currentCard.data;
    return (
      <Fragment>
        {cardInfo ? (
          <Toggle>
            {({ on, toggle }) => (
              <Fragment>
                <div className="row">
                  <div className="col-md">
                    <strong className="d-block">Current Card:</strong>
                    Visa ending in {cardInfo.attributes.last4}
                  </div>
                  <div className="col-md-auto">
                    <div className="btn-group">
                      <button
                        className="btn btn-primary"
                        title="Manage Card"
                        onClick={e => toggle()}
                      >
                        <small>
                          <FontAwesomeIcon icon="pen" />
                        </small>
                      </button>
                      <Toggle>
                        {toggleprops => (
                          <Fragment>
                            <ConfirmModal
                              on={toggleprops.on}
                              toggle={toggleprops.toggle}
                              onConfirm={deleteConfirm}
                            >
                              Are you sure you want this card removed?
                            </ConfirmModal>
                            <button
                              className="btn btn-danger"
                              title="Delete Card"
                              onClick={e => toggleprops.toggle()}
                            >
                              <small>
                                <FontAwesomeIcon icon="trash" />
                              </small>
                            </button>
                          </Fragment>
                        )}
                      </Toggle>
                    </div>
                  </div>
                </div>
                {/**
            .row
            */}
                <div className={`${!on && "d-none"}`}>
                  <CreditCardForm
                    hidden={["number", "cvc"]}
                    required={["name", "exp_month", "exp_year"]}
                    submitText="Update Credit Card"
                    handleSubmit={handleSubmit}
                  />
                </div>
              </Fragment>
            )}
          </Toggle>
        ) : (
          <div className="text-center">Loading...</div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentCard: state.client.currentCard,
});

export default connect(mapStateToProps)(ManageCardForm);
