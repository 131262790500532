import React, { Component } from "react";
import { connect } from "react-redux";

import { authActions } from "../../../actions";

class NewPasswordForm extends Component {
  state = {
    password: "",
    confirm_password: "",
    required: ["password", "confirm_password"],
    touched: [],
  };

  handleChange = e => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  isInvalid = () => {
    const empty = this.state.required.some(
      v =>
        this.state[v] === null ||
        this.state[v] === "" ||
        typeof this.state[v] === "undefined",
    );

    if (empty) {
      return true;
    }
    if (this.state.confirm_password !== this.state.password) {
      return true;
    }

    // Else Valid
    return false;
  };

  handleSubmit = e => {
    e.preventDefault();
    const { required, touched, ...values } = this.state;
    this.props.handleSubmit(values);
  };

  handleBlur = e => {
    const name = e.target.name;
    this.setState({
      touched: [...this.state.touched, name],
    });
  };

  isRequired = name => this.state.required.includes(name);

  invalidClass = name =>
    this.state[name] == "" &&
    this.isRequired(name) &&
    this.state.touched.includes(name)
      ? true
      : false;

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-md">
            <div className="form-group">
              <label>Password *</label>
              <input
                type="password"
                className={`form-control ${this.invalidClass("password") &&
                  "is-invalid"}`}
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              {this.invalidClass("password") && (
                <div className="invalid-feedback">This field is required</div>
              )}
            </div>
          </div>
          <div className="col-md">
            <div className="form-group">
              <label>Confirm Password *</label>
              <input
                type="password"
                className={`form-control ${(this.invalidClass(
                  "confirm_password",
                ) ||
                  this.state.confirm_password !== this.state.password) &&
                  "is-invalid"}`}
                name="confirm_password"
                value={this.state.confirm_password}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              {(this.invalidClass("confirm_password") ||
                this.state.confirm_password !== this.state.password) && (
                <div className="invalid-feedback">
                  This field is required and must be equal to first password
                </div>
              )}
            </div>
          </div>
        </div>

        <button
          className="btn btn-success btn-block mt-4"
          disabled={this.isInvalid()}
          type="submit"
        >
          Reset Password
        </button>
      </form>
    );
  }
}

export default connect(null)(NewPasswordForm);
