import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import Toggle from "../../../components/utilities/Toggle";
import Layout from "../../../components/modules/Layout";
import ManageClientForm from "../../../components/forms/ManageClientForm";
import ConfirmModal from "../../../components/modals/ConfirmModal";

import { routeConstants } from "../../../constants";
import { clientActions } from "../../../actions";
import { apiServices } from "../../../services";

class ManageClientPage extends Component {
  state = {
    currentClient: [],
  };

  async componentDidMount() {
    this.getUserInfo();
  }

  getUserInfo = async () => {
    const id = this.props.match.params.id;
    const client = await apiServices.getClient(id);
    this.setState({
      currentClient: client.data,
    });
  };

  deleteClient = ({ toggle }) => {
    const id = this.props.match.params.id;
    this.props.dispatch(clientActions.deleteClient(id));

    // Close Modal
    toggle();
  };

  render() {
    const { currentClient } = this.state;
    const id = this.props.match.params.id;
    return (
      <Layout history={this.props.history}>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={routeConstants.CLIENTS}>Frontward Clients</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/client/${id}`}>Client Details</Link>
            </li>
            <li className="breadcrumb-item active">Manage Client</li>
          </ol>
        </nav>

        {currentClient && currentClient.attributes && (
          <Fragment>
            <div className="row">
              <div className="col">
                <h1>Manage Client Account</h1>
              </div>
              <div className="col-auto">
                <div className="btn-group">
                  <Toggle>
                    {({ on, toggle }) => (
                      <Fragment>
                        <ConfirmModal
                          on={on}
                          toggle={toggle}
                          onConfirm={this.deleteClient}
                        >
                          Are you sure you want to delete{" "}
                          {currentClient.attributes.first_name}{" "}
                          {currentClient.attributes.last_name}?
                        </ConfirmModal>
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={this.deleteClient}
                          onClick={e => toggle()}
                        >
                          <FontAwesomeIcon icon="trash" />
                        </button>
                      </Fragment>
                    )}
                  </Toggle>
                </div>
              </div>
            </div>
            <ManageClientForm user={currentClient} />
          </Fragment>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  client: state.client,
});

export default connect(mapStateToProps)(ManageClientPage);
