import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import * as moment from "moment";

import Layout from "../../../components/modules/Layout";
import DetailItem from "../../../components/modules/DetailItem";
import ManageCardForm from "../../../components/forms/ManageCardForm";
import NewCardForm from "../../../components/forms/NewCardForm";

import { routeConstants } from "../../../constants";
import { clientActions, domainActions } from "../../../actions";

class ManageClientCardPage extends Component {
  componentDidMount() {
    this.getDomainInfo();
  }

  getClientName = () => {
    const { currentDomain } = this.props;
    const ref = currentDomain.data.relationships.client.data;
    const client = currentDomain.included.find(rel =>
      rel.id === ref.id && rel.type === rel.type ? rel : null,
    );
    return `${client.attributes.first_name} ${client.attributes.last_name}`;
  };

  loadClientCard = () => {
    const id = this.props.match.params.id;
    this.props.dispatch(clientActions.getCard(id));
  };

  getDomainInfo = () => {
    const id = this.props.match.params.id;
    this.props.dispatch(domainActions.getDomain(id));
  };

  deleteCard = ({ toggle }) => {
    toggle();
    const id = this.props.match.params.id;
    this.props.dispatch(clientActions.deleteCard(id));
  };

  newCard = data => {
    const id = this.props.match.params.id;
    this.props.dispatch(clientActions.createCard({ data, id }));
  };

  manageCard = data => {
    const id = this.props.match.params.id;
    this.props.dispatch(clientActions.updateCard({ data, id }));
  };

  linkCard = data => {
    const id = this.props.match.params.id;
    this.props.dispatch(clientActions.linkCard({ data, id }));
  };

  render() {
    const { currentDomain } = this.props;
    const domainInfo = currentDomain.data;
    const id = this.props.match.params.id;

    return (
      <Layout history={this.props.history}>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={routeConstants.CLIENTS}>Frontward Clients</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/domain/${id}`}>Domain Details</Link>
            </li>
            <li className="breadcrumb-item active">Manage Client Card</li>
          </ol>
        </nav>

        {domainInfo && domainInfo.attributes && (
          <Fragment>
            <div className="row">
              <div className="col">
                <h1>Manage Client Credit Card Account</h1>

                <div className="row">
                  <div className="col-md">
                    <DetailItem
                      label="Client Name"
                      data={`${this.getClientName()}`}
                    />
                  </div>
                  <div className="col-md">
                    <DetailItem
                      label="Client Domain"
                      data={`${domainInfo.attributes.domain}`}
                    />
                  </div>
                </div>

                {domainInfo.attributes.last_payment_failed ? (
                  <div className="alert alert-danger mt-4">
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      className="mr-2"
                    />
                    <strong>Failed Payment Notice</strong>
                    <br />
                    Last Payment Failed:{" "}
                    {moment(
                      domainInfo.attributes.last_payment_timestamp,
                    ).format("MMMM Do YYYY")}
                  </div>
                ) : null}

                <div className="card mt-4">
                  <div className="card-body">
                    {domainInfo.attributes.card_id ? (
                      <ManageCardForm
                        cardID={domainInfo.attributes.card_id}
                        loadCard={this.loadClientCard}
                        deleteConfirm={this.deleteCard}
                        handleSubmit={this.manageCard}
                      />
                    ) : (
                      <NewCardForm
                        stripeAdd
                        handleSubmit={this.newCard}
                        handleLink={this.linkCard}
                      />
                    )}
                  </div>
                </div>
                {/*
                  Card Ending Div
                */}
              </div>
            </div>
          </Fragment>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  currentDomain: state.client.currentDomain,
});

export default connect(mapStateToProps)(ManageClientCardPage);
