import { put, takeEvery } from "redux-saga/effects";
import { errorConstants, appConstants } from "../constants";

function* errorToast(action) {
  const { payload } = action;
  yield put({
    type: appConstants.ADD_TOAST,
    payload: {
      title: payload.title ? payload.title : `Error Occurred`,
      message: payload.message,
      type: "danger",
    },
  });
}

export default function* errorSaga() {
  yield takeEvery(errorConstants.REQUEST_ERROR, errorToast);
}
