import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Header from "../Header";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import Spinner from "../../utilities/Spinner";
import Toastr from "../../utilities/Toastr";

class Layout extends React.Component {
  render() {
    return (
      <div className="site">
        <Helmet>
          <title>Frontward Web Hosting Client Portal</title>
        </Helmet>
        <Toastr />
        {this.props.app.loading && <Spinner />}
        <Header
          dispatch={this.props.dispatch}
          isLoggedIn={this.props.auth.isLoggedIn}
        />
        <section className="container">
          <div className="row">
            {this.props.auth.isLoggedIn && !this.props.hideSidebar && (
              <div className="col-auto d-none d-md-block">
                <Sidebar />
              </div>
            )}
            <div className="col">
              <div className="card">
                <div className="card-body">{this.props.children}</div>
              </div>
            </div>
          </div>
        </section>
        <Footer isLoggedIn={this.props.auth.isLoggedIn} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
  };
};

export default connect(mapStateToProps)(Layout);
