import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const ClientRow = ({ client }) => (
  <tr>
    <td>
      <div className="row">
        <div className="col-md-auto">
          <small
            className={`mr-2 ${
              client.attributes.has_cards && !client.attributes.has_fail
                ? "text-success"
                : "text-danger"
            } ${client.attributes.has_fail && `text-danger`}`}
          >
            {client.attributes.has_fail ? (
              <FontAwesomeIcon icon="times-circle" />
            ) : (
              <Fragment>
                {client.attributes.has_cards ? (
                  <FontAwesomeIcon icon="check-circle" />
                ) : (
                  <FontAwesomeIcon icon="times-circle" />
                )}
              </Fragment>
            )}
          </small>
        </div>
        <div className="col-md">
          <Link to={`/client/${client.id}`}>
            {client.attributes.first_name} {client.attributes.last_name}
          </Link>
          <div>
            <FontAwesomeIcon icon="globe" className="mr-2" />
            {client.attributes.num_domains > 1 ||
            client.attributes.num_domains <= 0 ? (
              <span>{client.attributes.num_domains} Domains</span>
            ) : (
              <span>{client.attributes.sample_domain}</span>
            )}
          </div>
        </div>
      </div>
      <div />
    </td>
    <td className="text-right">
      <div className="btn-group">
        <Link
          className="btn btn-primary"
          to={`/client/${client.id}`}
          title="Client Details"
        >
          <FontAwesomeIcon icon="user-circle" />
        </Link>
        <Link
          className="btn btn-warning"
          to={`/manage-client/${client.id}`}
          title="Manage Client"
        >
          <FontAwesomeIcon icon="pen" />
        </Link>
      </div>
    </td>
  </tr>
);

export default ClientRow;
