import React from "react";
import { connect } from "react-redux";

import Modal from "../../utilities/Modal";

import { authActions } from "../../../actions";

class ForgotPassword extends React.Component {
  state = {
    email: "",
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.dispatch(authActions.forgotPassword(this.state));
  };

  isInvalid = () => {
    if (this.state.email === "" || this.state.email === null) {
      return true;
    }
    return false;
  };

  render() {
    const { on, toggle } = this.props;

    return (
      <Modal on={on} toggle={toggle}>
        <h3>Forgot Password?</h3>
        <p>Enter your account email below and we will send a reset password</p>
        <form className="mt-4" onSubmit={this.handleSubmit} id="resetForm">
          <div className="form-group">
            <label htmlFor="email">Account Email:</label>
            <input
              type="email"
              className="form-control"
              placeholder="Email Address"
              name="email"
              id="email"
              onChange={this.handleChange}
              value={this.state.email}
            />
          </div>

          <button
            type="submit"
            className="btn btn-success btn-block"
            disabled={this.isInvalid()}
            form="resetForm"
          >
            Reset Password
          </button>
        </form>
      </Modal>
    );
  }
}

export default connect(null)(ForgotPassword);
