import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import client from "./client.reducer";
import auth from "./auth.reducer";
import app from "./app.reducer";
import account from "./account.reducer";

export default history =>
  combineReducers({
    router: connectRouter(history),
    client,
    auth,
    app,
    account,
  });
