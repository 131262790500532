import { call, take, race, put } from "redux-saga/effects";
import {
  authConstants,
  errorConstants,
  routeConstants,
  apiConstants,
  appConstants,
} from "../constants";
import { push } from "connected-react-router";
import { apiServices, userServices } from "../services";

import { getAccount } from "./accountSaga";

/**
 * Sends Email & Password To API
 * Then Authorizes On Bearer Token
 */
export function* authorize({ email, password, token }) {
  try {
    // Call On API
    if (email && password) {
      const response = yield call(apiServices.login, { email, password });
      token = response.token;
    }

    // Set On API
    yield call(apiServices.setAuthHeader, token);

    // Set In Localstoreage
    localStorage.setItem(apiConstants.AUTH_TOKEN, token);

    // Set User Info In Store
    const user = yield call(userServices.getUserInfo, token);
    yield put({ type: appConstants.SET_USER_INFO, payload: user });

    return token;
  } catch (error) {
    yield put({ type: errorConstants.REQUEST_ERROR, payload: error });
    return false;
  }
}

export default function* loginSaga() {
  while (true) {
    const request = yield take(authConstants.ACCOUNT_LOGIN);

    // Loading
    yield put({ type: appConstants.LOADING_STATUS, payload: true });

    const winner = yield race({
      auth: call(authorize, request.payload),
      logout: take(authConstants.ACCOUNT_LOGOUT),
    });

    if (winner.auth) {
      // Loading
      yield put({ type: appConstants.LOADING_STATUS, payload: false });
      yield put({ type: authConstants.ACCOUNT_LOGIN_STORE });

      // Load Account Info If Not Admin
      const user = yield call(userServices.getUserInfo);
      const isAdmin = yield call(userServices.isAdmin, user);
      if (!isAdmin) {
        yield call(getAccount);
      }

      yield put({
        type: appConstants.ADD_TOAST,
        payload: {
          title: `Login Success!`,
          message: "You are successfully logged into your account",
          type: "success",
        },
      });
      yield put(push(routeConstants.CLIENTS));
    }
  }
}
