export const authConstants = {
  ACCOUNT_LOGIN: "ACCOUNT_LOGIN",
  ACCOUNT_LOGIN_STORE: "ACCOUNT_LOGIN_STORE",
  ACCOUNT_CHECK: "ACCOUNT_CHECK",
  ACCOUNT_LOGOUT: "ACCOUNT_LOGOUT",
  ACCOUNT_LOGOUT_STORE: "ACCOUNT_LOGOUT_STORE",
  ACCOUNT_EXPIRE: "ACCOUNT_EXPIRE",
  ACCOUNT_ADMIN_STATUS: "ACCOUNT_ADMIN_STATUS",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  VERIFY_FORGOT_TOKEN: "VERIFY_FORGOT_TOKEN",
  RESET_PASSWORD: "RESET_PASSWORD",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
};
