export const clientConstants = {
  GET_CLIENT: "GET_CLIENT",
  STORE_CLIENT: "STORE_CLIENT",
  GET_CLIENTS: "GET_CLIENTS",
  STORE_CLIENTS: "STORE_CLIENTS",
  CREATE_CLIENT: "CREATE_CLIENT",
  CREATE_CLIENT_STORE: "CREATE_CLIENT_STORE",
  UPDATE_CLIENT: "UPDATE_CLIENT",
  UPDATE_CLIENT_STORE: "UPDATE_CLIENT_STORE",
  DELETE_CLIENT: "DELETE_CLIENT",
  DELETE_CLIENT_STORE: "DELETE_CLIENT_STORE",
  RESET_CLIENT_PASSWORD: "RESET_CLIENT_PASSWORD",
  CREATE_CLIENT_CARD: "CREATE_CLIENT_CARD",
  GET_CLIENT_CARD: "GET_CLIENT_CARD",
  UPDATE_CLIENT_CARD: "UPDATE_CLIENT_CARD",
  DELETE_CLIENT_CARD: "DELETE_CLIENT_CARD",
  STORE_CARD_INFO: "STORE_CARD_INFO",
  REMOVE_CARD_INFO: "REMOVE_CARD_INFO",
  LINK_STRIPE_CARD: "LINK_STRIPE_CARD",
};
