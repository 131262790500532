import { accountConstants } from "../constants";

/**
 * Function to get account info
 */
const getAccountInfo = () => ({ type: accountConstants.GET_ACCOUNT_INFO });

/**
 * Create Card
 */
const createCard = data => ({
  type: accountConstants.ADD_ACCOUNT_CARD,
  payload: data,
});

/**
 * Update Card
 */
const updateCard = data => ({
  type: accountConstants.UPDATE_ACCOUNT_CARD,
  payload: data,
});

/**
 * Delete Card
 */
const deleteCard = id => ({
  type: accountConstants.DELETE_ACCOUNT_CARD,
  payload: id,
});

/**
 * Get Card
 */
const getCard = id => ({
  type: accountConstants.GET_ACCOUNT_CARD,
  payload: id,
});

const getDomain = id => ({
  type: accountConstants.GET_ACCOUNT_DOMAIN,
  payload: id,
});

export const accountActions = {
  getAccountInfo,
  getCard,
  createCard,
  updateCard,
  deleteCard,
  getDomain,
};
