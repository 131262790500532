import React from "react";

import Modal from "../../utilities/Modal";

const ConfirmModal = ({ toggle, on, onConfirm, children }) => (
  <Modal on={on} toggle={toggle}>
    <h3>Confirm Delete?</h3>
    <span className="d-block mb-4">{children}</span>
    <div className="btn-group d-flex">
      <button
        className="btn btn-danger w-100"
        onClick={e => onConfirm({ toggle })}
      >
        Confirm!
      </button>
      <button className="btn btn-secondary w-100" onClick={toggle}>
        Cancel
      </button>
    </div>
  </Modal>
);

export default ConfirmModal;
