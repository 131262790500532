import { put, call, takeLatest, select } from "redux-saga/effects";
import {
  accountConstants,
  clientConstants,
  appConstants,
  errorConstants,
} from "../constants";
import { apiServices } from "../services";

import { request } from "./appSaga";

// Get Account Info
export function* getAccount() {
  const res = yield call(request, {
    fn: apiServices.getAccount,
  });
  if (res) {
    yield put({
      type: accountConstants.STORE_ACCOUNT,
      payload: res,
    });
  }
}

// Update Account
function* updateAccount(action) {
  const res = yield call(request, {
    fn: apiServices.updateAccount,
    params: action.payload,
  });
  if (res) {
    yield put({
      type: accountConstants.STORE_ACCOUNT,
      payload: res,
    });
  }
}

function* reloadDomain() {
  const domain = yield select(currentDomain);
  yield put({
    type: accountConstants.GET_ACCOUNT_DOMAIN,
    payload: domain.data.id,
  });
}

const currentDomain = state => state.account.domain;

// Card Functions
function* createCard(action) {
  const res = yield call(request, {
    fn: apiServices.addCard,
    params: action.payload,
  });
  if (res) {
    yield put({ type: clientConstants.STORE_CARD_INFO, payload: res });
    yield call(reloadDomain);
    yield put({ type: accountConstants.GET_ACCOUNT_INFO });
    yield put({ type: accountConstants.UPDATE_CARD_ID, payload: res.id });
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Credit Card Added`,
        message: `Card has been added to account`,
        type: "success",
      },
    });
  }
}

function* getCard(action) {
  try {
    const response = yield call(apiServices.getCard, action.payload);
    yield put({ type: clientConstants.STORE_CARD_INFO, payload: response });
  } catch (error) {
    yield put({ type: errorConstants.REQUEST_ERROR, payload: error });
  }
}

function* updateCard(action) {
  const res = yield call(request, {
    fn: apiServices.updateCard,
    params: action.payload,
  });
  if (res) {
    yield put({ type: clientConstants.STORE_CARD_INFO, payload: res });
    yield call(reloadDomain);
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Credit Card Updated`,
        message: `Card has been updated on account`,
        type: "success",
      },
    });
  }
}

function* deleteCard(action) {
  const res = yield call(request, {
    fn: apiServices.deleteCard,
    params: action.payload,
  });
  if (res) {
    yield put({ type: clientConstants.REMOVE_CARD_INFO });
    yield call(reloadDomain);
    yield put({ type: accountConstants.GET_ACCOUNT_INFO });
    yield put({ type: accountConstants.UPDATE_CARD_ID, payload: null });
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Credit Card Deleted`,
        message: `Card has been removed from account`,
        type: "success",
      },
    });
  }
}

function* getDomain(action) {
  const res = yield call(request, {
    fn: apiServices.getClientDomain,
    params: action.payload,
  });
  if (res) {
    yield put({
      type: accountConstants.STORE_ACCOUNT_DOMAIN,
      payload: res,
    });
  }
}

export default function* accountSaga() {
  yield takeLatest(accountConstants.GET_ACCOUNT_INFO, getAccount);
  yield takeLatest(accountConstants.UPDATE_ACCOUNT_INFO, updateAccount);
  yield takeLatest(accountConstants.ADD_ACCOUNT_CARD, createCard);
  yield takeLatest(accountConstants.GET_ACCOUNT_CARD, getCard);
  yield takeLatest(accountConstants.UPDATE_ACCOUNT_CARD, updateCard);
  yield takeLatest(accountConstants.DELETE_ACCOUNT_CARD, deleteCard);
  yield takeLatest(accountConstants.GET_ACCOUNT_DOMAIN, getDomain);
}
