import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import { routeConstants } from "../constants";

import AdminRoute from "../components/utilities/AdminRoute";
import ClientRoute from "../components/utilities/ClientRoute";

import LoginPage from "../pages/Public/LoginPage";
import ResetPasswordPage from "../pages/Public/ResetPasswordPage";
import ClientsPage from "../pages/Admin/ClientsPage";
import ManageClientPage from "../pages/Admin/ManageClientPage";
import NewClientPage from "../pages/Admin/NewClientPage";
import ViewClientPage from "../pages/Admin/ViewClientPage";
import LoadingPage from "../pages/Public/LoadingPage";
import AccountPage from "../pages/Client/AccountPage";
import ManageCardPage from "../pages/Client/ManageCardPage";
import ManageClientCardPage from "../pages/Admin/ManageClientCardPage";
import SettingsPage from "../pages/SettingsPage";
import ManageDomainPage from "../pages/Admin/ManageDomainPage";
import NewDomainPage from "../pages/Admin/NewDomainPage";
import PrivateRoute from "../components/utilities/PrivateRoute";
import ViewDomainPage from "../pages/Admin/ViewDomainPage";

class AppRouter extends Component {
  render() {
    const { initCheck } = this.props.auth;

    return (
      <Fragment>
        {!initCheck ? (
          <LoadingPage />
        ) : (
          <ConnectedRouter history={this.props.history}>
            <Switch>
              <Route path={routeConstants.LOGIN} component={LoginPage} />

              <Route
                path={routeConstants.RESET_PASSWORD}
                component={ResetPasswordPage}
              />

              <PrivateRoute
                path={routeConstants.ACCOUNT_SETTING}
                component={SettingsPage}
              />

              <AdminRoute
                path={routeConstants.NEW_CLIENT}
                component={NewClientPage}
                exact
              />

              <AdminRoute
                path={routeConstants.MANAGE_CLIENT}
                component={ManageClientPage}
              />

              <AdminRoute
                path={routeConstants.MANAGE_CLIENT_CARD}
                component={ManageClientCardPage}
                exact
              />

              <AdminRoute
                path={routeConstants.NEW_DOMAIN}
                component={NewDomainPage}
                exact
              />

              <AdminRoute
                path={routeConstants.MANAGE_DOMAIN}
                component={ManageDomainPage}
              />

              <AdminRoute
                path={routeConstants.VIEW_DOMAIN}
                component={ViewDomainPage}
              />

              <AdminRoute
                path={routeConstants.VIEW_CLIENT}
                component={ViewClientPage}
              />

              <ClientRoute
                path={routeConstants.ACCOUNT}
                component={AccountPage}
              />

              <ClientRoute
                path={routeConstants.MANAGE_CARD}
                component={ManageCardPage}
              />

              <AdminRoute
                path={routeConstants.CLIENTS}
                component={ClientsPage}
                exact
              />
            </Switch>
          </ConnectedRouter>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AppRouter);
