import React, { Component } from "react";
import { Link } from "react-router-dom";

import Layout from "../../../components/modules/Layout";
import NewDomainForm from "../../../components/forms/NewDomainForm";

import { routeConstants } from "../../../constants";

class NewDomainPage extends Component {
  render() {
    const id = this.props.match.params.id;
    return (
      <Layout history={this.props.history}>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={routeConstants.CLIENTS}>Frontward Clients</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/client/${id}`}>Client Details</Link>
            </li>
            <li className="breadcrumb-item active">New Domain</li>
          </ol>
        </nav>

        <h1>Add New Domain To Client</h1>
        <p>Please enter domain details below</p>

        <NewDomainForm id={id} />
      </Layout>
    );
  }
}

export default NewDomainPage;
