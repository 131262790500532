import React, { Component } from "react";
import { connect } from "react-redux";

import { domainActions } from "../../../actions";

class ManageDomainForm extends Component {
  state = {
    domain: "",
    has_email: "",
    num_emails: "",
    required: ["domain", "has_email"],
  };

  componentDidMount() {
    const domain = this.props.domain.attributes;
    this.setState({
      domain: domain.domain,
      has_email: domain.has_email ? "true" : "false",
      num_emails: domain.num_emails,
    });
  }

  handleChange = e => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
    });

    // Has Email Or Not
    if (
      e.currentTarget.name === "has_email" &&
      e.currentTarget.value === "false"
    ) {
      this.setState({
        num_emails: null,
      });
    }
  };

  isInvalid = () => {
    const empty = this.state.required.some(
      v =>
        this.state[v] === null ||
        this.state[v] === "" ||
        typeof this.state[v] === "undefined",
    );
    if (empty) {
      return empty;
    }
    if (!this.validURL(this.state.domain)) {
      return true;
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    // Get Values Besides Required
    const { required, ...data } = this.state;
    const id = this.props.domain.id;
    data.has_email = data.has_email === "true" ? true : false;
    data.domain = this.removeUrlProtocol(data.domain);
    this.props.dispatch(domainActions.updateDomain({ data, id }));
  };

  removeUrlProtocol = url => url.replace(/^(?:https?:\/\/)?(?:www\.)?/, "");

  validURL = url => {
    if (url === "" || typeof url === "undefined") {
      return true;
    }
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i",
    );
    return !!pattern.test(url);
  };

  isRequired = name => this.state.required.includes(name);

  invalidClass = name =>
    this.state[name] == "" && this.isRequired(name) ? true : false;

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-md">
            <div className="form-group">
              <label>Domain *</label>
              <input
                type="text"
                className={`form-control ${(this.invalidClass("domain") ||
                  !this.validURL(this.state.domain)) &&
                  "is-invalid"}`}
                name="domain"
                value={this.state.domain}
                onChange={this.handleChange}
              />
              {(this.invalidClass("domain") ||
                !this.validURL(this.state.domain)) && (
                <div className="invalid-feedback">Please enter valid url</div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <label>Does Client Need Emails? *</label>
            <div className="form-check">
              <input
                className={`form-check-input`}
                type="radio"
                id="has_email--true"
                name="has_email"
                checked={this.state.has_email === "true"}
                value={true}
                onChange={this.handleChange}
              />
              <label className="form-check-label">Has Email(s)</label>
            </div>
            <div className="form-check">
              <input
                className={`form-check-input`}
                type="radio"
                id="has_email--false"
                name="has_email"
                checked={this.state.has_email === "false"}
                value={false}
                onChange={this.handleChange}
              />
              <label className="form-check-label">No Email(s)</label>
            </div>
          </div>
          {this.state.has_email === "true" && (
            <div className="col-md">
              <div className="form-group">
                <label>Number Of Emails</label>
                <input
                  type="text"
                  className={`form-control`}
                  name="num_emails"
                  value={this.state.num_emails}
                  defaultValue={""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          )}
        </div>

        <button
          className="btn btn-success btn-block mt-4"
          disabled={this.isInvalid()}
          type="submit"
        >
          Update Domain
        </button>
      </form>
    );
  }
}

export default connect(null)(ManageDomainForm);
