import { authConstants } from "../constants";

const initState = {
  isLoggedIn: false,
  isAdmin: false,
  initCheck: false,
};

export default function auth(state = initState, action) {
  switch (action.type) {
    case authConstants.ACCOUNT_CHECK:
      return {
        ...state,
        initCheck: true,
        isLoggedIn: action.payload,
      };

    case authConstants.ACCOUNT_LOGIN_STORE:
      return {
        ...state,
        isLoggedIn: true,
      };

    case authConstants.ACCOUNT_LOGOUT_STORE:
      return {
        ...state,
        isLoggedIn: false,
      };

    case authConstants.ACCOUNT_ADMIN_STATUS:
      return {
        ...state,
        isAdmin: action.payload,
      };

    default:
      return state;
  }
}
