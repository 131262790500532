import React from "react";

class Toast extends React.Component {
  componentDidMount() {
    this.startCountDown();
  }
  componentWillUnmount() {
    this.clearCountdown();
  }

  startCountDown = () => {
    this.toastCountdown = setInterval(() => {
      this.props.removeToast();
    }, 5000);
  };

  clearCountdown = () => {
    clearInterval(this.toastCountdown);
  };

  handleClick = e => {
    this.clearCountdown();
    this.props.removeToast();
  };

  render() {
    const { type, title, children } = this.props;
    return (
      <div
        onClick={this.handleClick}
        className={`toast ${type === "success" && "toast--success"} ${type ===
          "danger" && "toast--danger"} ${type === "warning" &&
          "toast--warning"} ${type === "info" && "toast--info"}`}
      >
        <div className="toast-header">
          <strong className="mr-auto">{title}</strong>
          <button type="button" className="ml-2 mb-1 close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="toast-body">{children}</div>
      </div>
    );
  }
}

export default Toast;
