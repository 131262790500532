import { clientConstants, domainConstants } from "../constants";

const initState = {
  clients: [],
  totalClients: 0,
  currentClient: [],
  currentCard: [],
  currentDomain: [],
};

export default function client(state = initState, action) {
  switch (action.type) {
    case domainConstants.STORE_DOMAIN:
      return {
        ...state,
        currentDomain: action.payload,
      };

    case clientConstants.STORE_CLIENTS:
      return {
        ...state,
        clients: action.payload,
      };

    case clientConstants.STORE_CLIENT:
      return {
        ...state,
        currentClient: action.payload,
      };

    case clientConstants.STORE_CARD_INFO:
      return {
        ...state,
        currentClient: {
          ...state.currentClient,
          attributes: {
            ...state.currentClient.attributes,
            card_id: action.payload.id,
          },
        },
        currentCard: action.payload,
      };

    // Remove Card ID and Card INFO
    case clientConstants.REMOVE_CARD_INFO:
      return {
        ...state,
        currentClient: {
          ...state.currentClient,
          attributes: {
            ...state.currentClient.attributes,
            card_id: null,
          },
        },
        currentCard: [],
      };

    default:
      return state;
  }
}
