import { appConstants, errorConstants } from "../constants";

const initState = {
  loading: false,
  user: [],
  toasts: [],
};

export default function app(state = initState, action) {
  switch (action.type) {
    case appConstants.LOADING_STATUS:
      return {
        ...state,
        loading: action.payload,
      };

    case appConstants.SET_USER_INFO:
      return {
        ...state,
        user: action.payload,
      };

    case errorConstants.REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case appConstants.ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };

    case appConstants.REMOVE_TOAST:
      return {
        ...state,
        toasts: [
          ...state.toasts.slice(0, action.payload),
          ...state.toasts.slice(action.payload + 1),
        ],
      };

    default:
      return state;
  }
}
