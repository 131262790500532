import { put, call } from "redux-saga/effects";

import { errorConstants, appConstants } from "../constants";
import { apiServices } from "../services";

/**
 * Reduces API call redundancy
 * @param {data} fn = function called
 * @param {data} params = params passed to function called
 */
export function* request({ fn, params = null, customError = null }) {
  yield put({ type: appConstants.LOADING_STATUS, payload: true });
  try {
    // Call Function
    const response = yield call(fn, params);

    yield put({ type: appConstants.LOADING_STATUS, payload: false });
    return response;
  } catch (error) {
    if (customError) {
      yield put({ type: errorConstants.REQUEST_ERROR, payload: customError });
    } else {
      yield put({ type: errorConstants.REQUEST_ERROR, payload: error });
    }
    yield put({ type: appConstants.LOADING_STATUS, payload: false });
    return false;
  }
}
