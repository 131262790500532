export const routeConstants = {
  CLIENTS: "/",
  LOGIN: "/login",
  NEW_CLIENT: "/client",
  VIEW_CLIENT: "/client/:id",
  MANAGE_CLIENT: "/manage-client/:id",
  MANAGE_CLIENT_CARD: "/manage-client-card/:id",
  ACCOUNT: "/account",
  RESET_PASSWORD: "/reset-password/:token",
  ACCOUNT_SETTING: "/settings",
  VIEW_DOMAIN: "/domain/:id",
  MANAGE_DOMAIN: "/manage-domain/:id",
  MANAGE_CARD: "/manage-card/:id",
  NEW_DOMAIN: "/client/:id/domain",
};
