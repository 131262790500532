import { appConstants } from "../constants";

const setLoadingStatus = status => ({
  type: appConstants.LOADING_STATUS,
  payload: status,
});

const removeToast = ix => ({
  type: appConstants.REMOVE_TOAST,
  payload: ix,
});

export const appActions = {
  setLoadingStatus,
  removeToast,
};
