import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import * as moment from "moment";

import Layout from "../../../components/modules/Layout";
import ManageCardForm from "../../../components/forms/ManageCardForm";
import NewCardForm from "../../../components/forms/NewCardForm";

import { accountActions } from "../../../actions";
import { routeConstants } from "../../../constants";

class ManageCardPage extends Component {
  componentDidMount() {
    this.loadDomain();
  }

  loadCreditCard = () => {
    const id = this.props.match.params.id;
    this.props.dispatch(accountActions.getCard(id));
  };

  loadDomain() {
    const id = this.props.match.params.id;
    this.props.dispatch(accountActions.getDomain(id));
  }

  manageCard = data => {
    const id = this.props.match.params.id;
    this.props.dispatch(accountActions.updateCard({ data, id }));
  };

  newCard = data => {
    const id = this.props.match.params.id;
    this.props.dispatch(accountActions.createCard({ data, id }));
  };

  deleteCard = ({ toggle }) => {
    toggle();
    const id = this.props.match.params.id;
    this.props.dispatch(accountActions.deleteCard(id));
  };

  render() {
    const { domain } = this.props.account;
    return (
      <Layout history={this.props.history}>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={routeConstants.ACCOUNT}>My Account</Link>
            </li>
            <li className="breadcrumb-item active">Domain Credit Card</li>
          </ol>
        </nav>

        <div className="row">
          <div className="col">
            <h3 className="card-title mb-1">
              <i className="fas fa-user-circle" /> Manage Account
            </h3>
            <p className="text-secondary">
              Manage your account card details below
            </p>

            <div className="card">
              <div className="card-body">
                {domain && domain.data && (
                  <Fragment>
                    {domain.data.attributes.last_payment_failed ? (
                      <div className="alert alert-danger mt-4">
                        <FontAwesomeIcon
                          icon="exclamation-triangle"
                          className="mr-2"
                        />
                        <strong>Failed Payment Notice</strong>
                        <br />
                        Last Payment Failed:{" "}
                        {moment(
                          domain.data.attributes.last_payment_timestamp,
                        ).format("MMMM Do YYYY")}
                      </div>
                    ) : null}

                    {domain.data.attributes.card_id ? (
                      <ManageCardForm
                        cardID={domain.data.attributes.card_id}
                        loadCard={this.loadCreditCard}
                        deleteConfirm={this.deleteCard}
                        handleSubmit={this.manageCard}
                      />
                    ) : (
                      <NewCardForm handleSubmit={this.newCard} />
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  account: state.account,
});

export default connect(mapStateToProps)(ManageCardPage);
