import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Layout from "../../../components/modules/Layout";
import NewPasswordForm from "../../../components/forms/NewPasswordForm";

import { authActions } from "../../../actions";

class ResetPasswordPage extends Component {
  componentDidMount() {
    const token = this.props.match.params.token;
    this.verifyToken(token);
  }

  verifyToken = token => this.props.dispatch(authActions.verifyToken(token));

  handleSubmit = values => {
    values.token = this.props.match.params.token;
    this.props.dispatch(authActions.resetPassword(values));
  };

  render() {
    return (
      <Layout history={this.props.history} hideSidebar={true}>
        <h3>Reset Account Password</h3>
        <em className="text-secondary">
          Please fill out form below to reset password:
        </em>

        <div className="mt-4">
          <NewPasswordForm handleSubmit={this.handleSubmit} />
        </div>
      </Layout>
    );
  }
}

export default connect(null)(ResetPasswordPage);
