import React from "react";

const DetailItem = ({ label, data }) => (
  <div>
    <small className="d-block">{label}</small>
    <span className="d-block">{data}</span>
  </div>
);

export default DetailItem;
