import { fork, call } from "redux-saga/effects";
import initSaga from "./initSaga";
import loginSaga from "./loginSaga";
import logoutSaga from "./logoutSaga";
import clientSaga from "./clientSaga";
import accountSaga from "./accountSaga";
import errorSaga from "./errorSaga";
import passwordSaga from "./passwordSaga";
import domainSaga from "./domainSaga";

// Root Saga
export default function* rootSaga() {
  yield call(initSaga);
  yield fork(loginSaga);
  yield fork(logoutSaga);
  yield fork(clientSaga);
  yield fork(accountSaga);
  yield fork(errorSaga);
  yield fork(passwordSaga);
  yield fork(domainSaga);
}
