import { put, call, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";

import { routeConstants, appConstants, authConstants } from "../constants";
import { apiServices } from "../services";

import { request } from "./appSaga";

// Send Reset Email
function* forgotPassword(action) {
  // API Loading Request
  const res = yield call(request, {
    fn: apiServices.forgotPass,
    params: { email: action.payload },
  });
  if (res) {
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Check Your Email`,
        message: "Reset Password Link Has Been Sent To Your Account",
        type: "success",
      },
    });
  }
}

// Verify Token Exists
function* verifyToken(action) {
  const res = yield call(request, {
    fn: apiServices.verifyForgotToken,
    params: action.payload,
  });
  if (!res) {
    yield put(push(routeConstants.LOGIN));
  }
}

// Reset Password
function* resetPassword(action) {
  const res = yield call(request, {
    fn: apiServices.resetPass,
    params: action.payload,
  });
  if (res) {
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Password Has Been Reset`,
        message: "Please login into your account with new password",
        type: "success",
      },
    });

    yield put(push(routeConstants.LOGIN));
  }
}

function* updatePassword(action) {
  const res = yield call(request, {
    fn: apiServices.updatePass,
    params: action.payload,
  });
  if (res) {
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Password Has Been Updated`,
        message: "Next time you login use your new password",
        type: "success",
      },
    });
  }
}

// Forgot Password Saga
export default function* passwordSaga() {
  yield takeLatest(authConstants.FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(authConstants.VERIFY_FORGOT_TOKEN, verifyToken);
  yield takeLatest(authConstants.RESET_PASSWORD, resetPassword);
  yield takeLatest(authConstants.UPDATE_PASSWORD, updatePassword);
}
