import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/browser";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faPlus,
  faSignOutAlt,
  faCreditCard,
  faUserCircle,
  faTimes,
  faPen,
  faTrash,
  faCheckCircle,
  faTimesCircle,
  faEnvelope,
  faGlobe,
  faSearch,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";

import "./styles/index.scss";

// Font Awesome React Component
library.add(
  faPlus,
  faSignOutAlt,
  faCreditCard,
  faUserCircle,
  faTimes,
  faPen,
  faTrash,
  faCheckCircle,
  faTimesCircle,
  faEnvelope,
  faGlobe,
  faSearch,
  faExclamationTriangle
);

// Debugging
Sentry.init({
  dsn: "https://b9ac7803255946748a00955ad5b7499e@sentry.io/4928766"
});

ReactDOM.render(<App />, document.getElementById("root"));
