export const accountConstants = {
  GET_ACCOUNT_INFO: "GET_ACCOUNT_INFO",
  STORE_ACCOUNT: "STORE_ACCOUNT",
  UPDATE_ACCOUNT_INFO: "UPDATE_ACCOUNT_INFO",
  ADD_ACCOUNT_CARD: "ADD_ACCOUNT_CARD",
  GET_ACCOUNT_CARD: "GET_ACCOUNT_CARD",
  UPDATE_ACCOUNT_CARD: "UPDATE_ACCOUNT_CARD",
  DELETE_ACCOUNT_CARD: "DELETE_ACCOUNT_CARD",
  UPDATE_CARD_ID: "UPDATE_CARD_ID",
  GET_ACCOUNT_DOMAIN: "GET_ACCOUNT_DOMAIN",
  STORE_ACCOUNT_DOMAIN: "STORE_ACCOUNT_DOMAIN",
};
