import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import Toggle from "../../../components/utilities/Toggle";
import Layout from "../../../components/modules/Layout";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import ManageDomainForm from "../../../components/forms/ManageDomainForm";

import { routeConstants } from "../../../constants";
import { domainActions } from "../../../actions";
import { apiServices } from "../../../services";

class ManageClientPage extends Component {
  state = {
    currentDomain: [],
  };

  async componentDidMount() {
    this.getDomainInfo();
  }

  getDomainInfo = async () => {
    const id = this.props.match.params.id;
    const domain = await apiServices.getDomain(id);
    this.setState({
      currentDomain: domain.data,
    });
  };

  deleteDomain = ({ toggle }) => {
    const id = this.props.match.params.id;
    this.props.dispatch(domainActions.deleteDomain(id));

    // Close Modal
    toggle();
  };

  render() {
    const { currentDomain } = this.state;
    const id = this.props.match.params.id;
    return (
      <Layout history={this.props.history}>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={routeConstants.CLIENTS}>Frontward Clients</Link>
            </li>
            {currentDomain.attributes && (
              <li className="breadcrumb-item">
                <Link
                  to={`/client/${currentDomain.relationships.client.data.id}`}
                >
                  Client Details
                </Link>
              </li>
            )}
            <li className="breadcrumb-item">
              <Link to={`/domain/${id}`}>Domain Details</Link>
            </li>
            <li className="breadcrumb-item active">Manage Domain</li>
          </ol>
        </nav>

        {currentDomain && currentDomain.attributes && (
          <Fragment>
            <div className="row">
              <div className="col">
                <h1>Manage Domain</h1>
              </div>
              <div className="col-auto">
                <div className="btn-group">
                  <Toggle>
                    {({ on, toggle }) => (
                      <Fragment>
                        <ConfirmModal
                          on={on}
                          toggle={toggle}
                          onConfirm={this.deleteDomain}
                        >
                          Are you sure you want to delete{" "}
                          {currentDomain.attributes.domain}?
                        </ConfirmModal>
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={e => toggle()}
                        >
                          <FontAwesomeIcon icon="trash" />
                        </button>
                      </Fragment>
                    )}
                  </Toggle>
                </div>
              </div>
            </div>
            <ManageDomainForm domain={currentDomain} />
          </Fragment>
        )}
      </Layout>
    );
  }
}

export default connect(null)(ManageClientPage);
