import { put, call } from "redux-saga/effects";

import * as moment from "moment-timezone";
import * as jwt from "jsonwebtoken";

import { authorize } from "./loginSaga";
import { authConstants, apiConstants } from "../constants";

import { getAccount } from "./accountSaga";

export default function* initSaga() {
  const token = localStorage.getItem(apiConstants.AUTH_TOKEN);
  let payload = false;
  if (token) {
    const decodedToken = jwt.decode(token);
    const current = moment()
      .tz("America/New_York")
      .unix();

    // If Token Is Active
    if (decodedToken.exp > current) {
      payload = true;
      yield call(authorize, { token });

      // If Not Admin
      if (!decodedToken.admin) {
        yield call(getAccount);
      }
    }
  }

  yield put({ type: authConstants.ACCOUNT_CHECK, payload });
}
