import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Layout from "../../../components/modules/Layout";
import ClientDetails from "../../../components/modules/ClientDetails";
import DomainsTable from "../../../components/tables/DomainsTable";

import { routeConstants } from "../../../constants";
import { apiServices } from "../../../services/api.services";

class ViewClientPage extends Component {
  state = {
    currentClient: [],
    relationships: [],
  };

  async componentDidMount() {
    this.getUserInfo();
  }

  getUserInfo = async () => {
    const id = this.props.match.params.id;
    const client = await apiServices.getClient(id);
    this.setState({
      currentClient: client.data,
      relationships: client.included,
    });
  };

  getDomains() {
    const { currentClient, relationships } = this.state;
    if (currentClient.attributes.num_domains <= 0) {
      return [];
    }

    return this.state.currentClient.relationships.domains.data.map(domain =>
      relationships.find(
        rel => rel.type === domain.type && rel.id === domain.id,
      ),
    );
  }

  render() {
    const id = this.props.match.params.id;
    const { currentClient } = this.state;
    return (
      <Layout history={this.props.history}>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={routeConstants.CLIENTS}>Frontward Clients</Link>
            </li>
            <li className="breadcrumb-item active">Client Details</li>
          </ol>
        </nav>

        {currentClient && currentClient.attributes && (
          <Fragment>
            <div className="row">
              <div className="col">
                <h1 className="d-inline">
                  {currentClient.attributes.first_name}{" "}
                  {currentClient.attributes.last_name} Account
                </h1>
              </div>
              <div className="col-auto">
                <div className="btn-group">
                  <Link
                    to={`/manage-client/${id}`}
                    className="btn btn-primary"
                    title="Manage Client Details"
                  >
                    <FontAwesomeIcon icon="pen" />
                  </Link>
                </div>
              </div>
            </div>

            <ClientDetails data={currentClient} admin={true} />

            <div className="mt-4">
              <div className="row mb-2">
                <div className="col-md">
                  <h4>{currentClient.attributes.first_name}'s Domains</h4>
                </div>
                <div className="col-md-auto">
                  <div className="btn-group">
                    <Link
                      to={`/client/${id}/domain`}
                      className="btn btn-success"
                      title="New Client Domain"
                    >
                      Add Domain
                    </Link>
                  </div>
                </div>
              </div>

              <DomainsTable domains={this.getDomains()} admin={true} />
            </div>
          </Fragment>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  client: state.client,
});

export default connect(mapStateToProps)(ViewClientPage);
