import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { routeConstants } from "../../../constants";
import { userServices } from "../../../services";
import PrivateRoute from "../PrivateRoute";

const ClientRoute = ({ app, ...rest }) => {
  return !userServices.isAdmin(app.user) ? (
    <PrivateRoute {...rest} />
  ) : (
    <Redirect to={routeConstants.CLIENTS} />
  );
};
ClientRoute.propTypes = {
  app: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  app: state.app,
});

export default connect(mapStateToProps)(ClientRoute);
