import { put, call, takeLatest, select } from "redux-saga/effects";
import { push } from "connected-react-router";

import {
  clientConstants,
  errorConstants,
  appConstants,
  routeConstants,
  domainConstants,
} from "../constants";
import { apiServices } from "../services";

import { request } from "./appSaga";

function* createClient(action) {
  const res = yield call(request, {
    fn: apiServices.createClient,
    params: action.payload,
  });
  if (res) {
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Client Created`,
        message: "Successfully added new client",
        type: "success",
      },
    });
    yield put(push(`/client/${res.data.id}`));
  }
}

function* getClients(action) {
  const res = yield call(request, {
    fn: apiServices.getClients,
    params: action.payload,
  });
  if (res) {
    yield put({
      type: clientConstants.STORE_CLIENTS,
      payload: res.data,
    });
  }
}

function* updateClient(action) {
  const res = yield call(request, {
    fn: apiServices.updateClient,
    params: action.payload,
  });
  if (res) {
    yield put({
      type: clientConstants.UPDATE_CLIENT_STORE,
      action: res.data,
    });
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Client Details Updated`,
        message: `Client has been updated in database`,
        type: "success",
      },
    });
  }
}

function* deleteClient(action) {
  const res = yield call(request, {
    fn: apiServices.deleteClient,
    params: action.payload,
  });
  if (res) {
    yield put({
      type: clientConstants.DELETE_CLIENT_STORE,
      action: res.data,
    });
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Client Deleted`,
        message: `Client has been deleted from database`,
        type: "success",
      },
    });
    yield put(push(routeConstants.CLIENTS));
  }
}

function* getClient(action) {
  const res = yield call(request, {
    fn: apiServices.getClient,
    params: action.payload,
  });
  if (res) {
    yield put({ type: clientConstants.STORE_CLIENT, payload: res });
  }
}

// Card Functions
function* createClientCard(action) {
  const res = yield call(request, {
    fn: apiServices.adminAddCard,
    params: action.payload,
    customError: {
      title: "Invalid Card Information",
      message:
        "Please try to enter correct information or contact Frontward Hosting",
    },
  });
  if (res) {
    yield put({ type: clientConstants.STORE_CARD_INFO, payload: res });
    yield call(reloadDomain);
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Credit Card Added`,
        message: `Card has been added to client account`,
        type: "success",
      },
    });
  }
}

function* getClientCard(action) {
  try {
    const response = yield call(apiServices.adminGetCard, action.payload);
    yield put({ type: clientConstants.STORE_CARD_INFO, payload: response });
  } catch (error) {
    yield put({ type: errorConstants.REQUEST_ERROR, payload: error });
  }
}

function* updateClientCard(action) {
  const res = yield call(request, {
    fn: apiServices.adminUpdateCard,
    params: action.payload,
    customError: {
      title: "Invalid Card Information",
      message:
        "Please try to enter correct information or contact Frontward Hosting",
    },
  });
  if (res) {
    yield put({ type: clientConstants.STORE_CARD_INFO, payload: res });
    yield call(reloadDomain);
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Credit Card Updated`,
        message: `Card has been updated on client account`,
        type: "success",
      },
    });
  }
}

function* deleteClientCard(action) {
  const res = yield call(request, {
    fn: apiServices.adminDeleteCard,
    params: action.payload,
  });
  if (res) {
    yield put({ type: clientConstants.REMOVE_CARD_INFO });
    yield call(reloadDomain);
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Credit Card Deleted`,
        message: `Card has been removed client account`,
        type: "success",
      },
    });
  }
}

function* reloadDomain() {
  const domain = yield select(currentDomain);
  yield put({ type: domainConstants.GET_DOMAIN, payload: domain.data.id });
}

function* linkStripeCard(action) {
  const res = yield call(request, {
    fn: apiServices.linkCard,
    params: action.payload,
  });
  if (res) {
    yield put({ type: clientConstants.STORE_CARD_INFO, payload: res });
    yield call(reloadDomain);
    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Credit Card Linked`,
        message: `Card has been linked on client account`,
        type: "success",
      },
    });
  }
}

const currentDomain = state => state.client.currentDomain;

export default function* clientSaga() {
  // Client Functionality
  yield takeLatest(clientConstants.GET_CLIENTS, getClients);
  yield takeLatest(clientConstants.CREATE_CLIENT, createClient);
  yield takeLatest(clientConstants.UPDATE_CLIENT, updateClient);
  yield takeLatest(clientConstants.DELETE_CLIENT, deleteClient);
  yield takeLatest(clientConstants.GET_CLIENT, getClient);

  // Card Functionality
  yield takeLatest(clientConstants.CREATE_CLIENT_CARD, createClientCard);
  yield takeLatest(clientConstants.GET_CLIENT_CARD, getClientCard);
  yield takeLatest(clientConstants.UPDATE_CLIENT_CARD, updateClientCard);
  yield takeLatest(clientConstants.DELETE_CLIENT_CARD, deleteClientCard);
  yield takeLatest(clientConstants.LINK_STRIPE_CARD, linkStripeCard);
}
