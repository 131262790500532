import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Layout from "../../../components/modules/Layout";
import ClientsTable from "../../../components/tables/ClientsTable";

import { routeConstants } from "../../../constants";
import { clientActions } from "../../../actions";

class ClientsPage extends Component {
  componentDidMount() {
    this.getClients();
  }

  // Calls Redux Saga
  getClients = (params = []) => {
    this.props.dispatch(clientActions.getClients(params));
  };

  render() {
    return (
      <Layout history={this.props.history}>
        <div className="row">
          <div className="col">
            <h3 className="card-title mb-1">
              <i className="fas fa-user-circle" /> Frontward Clients
            </h3>
            <p className="text-secondary">View Frontward clients below</p>
          </div>
          <div className="col text-right">
            <Link
              to={routeConstants.NEW_CLIENT}
              className="btn btn-success"
              title="Add Client"
            >
              Add Client
            </Link>
          </div>
        </div>

        <ClientsTable client={this.props.client} getClients={this.getClients} />
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    client: state.client,
  };
};

export default connect(mapStateToProps)(ClientsPage);
