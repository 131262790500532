import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import * as moment from "moment";

import DetailItem from "../DetailItem";

const DomainDetails = ({ data, admin = false }) => {
  return (
    <Fragment>
      {data.attributes.last_payment_failed ? (
        <div className="alert alert-danger mt-4">
          <FontAwesomeIcon icon="exclamation-triangle" className="mr-2" />
          <strong>FAILED PAYMENT!</strong> on{" "}
          {moment(data.attributes.last_payment_timestamp).format(
            "MMMM Do YYYY",
          )}
          <br />
          <Link
            to={
              admin
                ? `/manage-client-card/${data.id}`
                : `/manage-card/${data.id}`
            }
          >
            Update Credit Card Here
          </Link>
        </div>
      ) : null}
      <div className="row mt-4">
        <div className="col-md">
          <DetailItem label="Domain" data={data.attributes.domain} />
        </div>
        {admin && (
          <div className="col-md">
            <DetailItem label="Stripe ID" data={data.attributes.stripe_id} />
          </div>
        )}
      </div>
      <div className="row pt-4">
        <div className="col-md">
          <DetailItem
            label="Has Email"
            data={data.attributes.has_email ? "Yes" : "No"}
          />
        </div>
        {data.attributes.has_email === 1 && (
          <div className="col-md">
            <DetailItem
              label="Number Emails"
              data={data.attributes.num_emails}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default DomainDetails;
