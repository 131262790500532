import { put, call, take } from "redux-saga/effects";
import {
  authConstants,
  routeConstants,
  apiConstants,
  appConstants,
} from "../constants";
import { push } from "connected-react-router";
import { apiServices } from "../services";

export default function* logoutSaga() {
  while (true) {
    yield take([authConstants.ACCOUNT_LOGOUT, authConstants.ACCOUNT_EXPIRE]);

    // Remove from api / localstorage / state
    yield call(apiServices.removeAuthHeader);

    localStorage.removeItem(apiConstants.AUTH_TOKEN);

    yield put({ type: authConstants.ACCOUNT_LOGOUT_STORE });

    yield put({
      type: appConstants.ADD_TOAST,
      payload: {
        title: `Logged Out`,
        message: "You are logged-out of your account",
        type: "success",
      },
    });

    // Redirect to login page
    yield put(push(routeConstants.LOGIN));
  }
}
